footer {
  --background: var(--color-background-footer);
  --foreground: var(--color-text-footer);
  min-height: 50vh;
  padding-block: 3em;
  background-color: var(--background);
  color: var(--foreground);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 20;

  .accent {
    --color: var(--color-text-footer-accent);
    font-weight: 500;
  }

  a {
    --foreground: var(--foreground);
    position: relative;
    text-decoration: none;
    font-weight: 400;
    color: var(--foreground);

    &:hover {
      color: var(--foreground);
      background-position: 100%;
    }

    &::before {
      --foreground: var(--foreground);
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.25em;
      width: 12.5%;
      height: 0.125em;
      background-color: var(--color-text-footer);
      transition: width 500ms ease-in;
    }

    &:where(:hover, :focus) {
      &:before {
        width: 100%;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .about {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      margin-block-end: var(--separation-block);

      .contact {
        font-size: 1em;
        text-align: center;
        margin-block-end: var(--separation-block-sm);
      }
    }

    .links {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;

      a {
        &::before {
          display: none;
        }
      }
    }

    .logo-container {
      text-align: center;

      img.last-logo {
        aspect-ratio: 510.236/708.661;
        width: 25vw;
        margin: 0 auto;
      }
    }

    .tech {
      color: var(--color-tech);
    }

    dl {
      dd {
        margin-block-end: 1em;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  footer {
    .container {
      .about {
        order: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;

        .contact {
          font-size: 1em;
          text-align: start;
          margin-block-end: var(--separation-block-sm);
        }
      }

      .logo-container {
        order: 2;

        img.last-logo {
          width: 10ch;
        }
      }


      flex-direction: row;

      .links {
        order: 3;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: start;
        align-items: flex-end;

        a {
          &::before {
            display: unset;
          }
        }
      }
    }
  }
}