nav.navbar {
  isolation: isolate;
  padding: 0 2em;
  color: var(--color-text);
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-background-navbar);
  position: fixed;
  z-index: 20;
  top: 0;
  // transition: background-color ease-in-out 500ms, opacity ease-in-out 500ms;


  label {
    font-size: 3em;
    opacity: 1;
    z-index: 3;

    img {
      height: 2rem;
      transform: rotate(0deg);
      transition: transform 500ms ease-in;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  input {
    display: none;
  }

  input:checked+.links {
    top: 0;
    opacity: 1;
    background-color: var(--color-background);
  }


  .links {
    position: absolute;
    z-index: 0;
    height: 100vh;
    padding-block-start: 4rem;
    padding-inline-start: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    top: -100vh;

    padding-block-end: 0em;
    overflow: hidden;
    opacity: 0.5;
    font-size: 0.9em;
    transition: opacity ease-in-out 500ms, top ease-in-out 500ms;

    a {
      display: block;
      font-size: 1.5em;
      color: var(--color-text);
      font-weight: 300;
      text-decoration: none;
      transition: color ease-in-out 500ms;

      &:focus,
      &:hover {
        color: var(--color-link-hover);
      }

      &.spacer {
        display: none;
        pointer-events: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  nav.navbar {
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;

    label {
      display: block;
      pointer-events: none;
    }

    // background-color: var(--color-secondary);
    .links {
      position: relative;
      display: flex;
      gap: 3ch;
      width: auto;
      flex-direction: row;
      justify-content: flex-end;
      height: auto;
      opacity: 1;
      padding-block-start: 0;
      padding-block-end: 0;
      top: unset;
      right: unset;
      z-index: 10;
      font-size: 0.75rem;

      a {
        z-index: 20;
        position: relative;
        outline: 0px solid var(--color-accent);
        transition: outline-offset 300ms ease-out;

        &.spacer {
          display: inline;
        }

        &:hover {
          outline: 1px solid var(--color-accent);
          outline-offset: 1ch;
        }

      }
    }

  }
}