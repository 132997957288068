.yt-container {
  display: none;
  opacity: 0;
}

@keyframes roundAndRound {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

footer {
  img.last-logo {
    transition: transform 800ms ease-in;

    &.twisted {
      transform: rotateY(180deg);
      transition: transform 600ms ease-in;
    }

    &.double-twisted {
      transform: rotate(180deg);
      transition: transform 400ms ease-in;
    }
  }
}

body.brainpower {
  footer {

    //transform: translateY(-0vh);
    //height: 100vh;
    .contact {
      transform: translateX(-100vw);
      opacity: 0;
    }

    img {
      animation-name: roundAndRound;
      animation-duration: 300ms;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }

  section {
    transition: transform 1000ms ease-in;
    transform: scale(0);
    max-height: 0px;
  }

  .yt-container {
    transition: opacity 2000ms ease-in;
    position: fixed;
    top: 10vh;
    left: 5vw;
    right: 5vw;
    display: block;
    opacity: 1;
    width: 90vw;
    min-height: 45vh;

    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
      min-height: 50vh;
    }
  }
}