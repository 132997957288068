.button {
  --background: black;
  --foreground: white;
  --border: white;
  padding: 0.5em 1em;

  background-color: var(--background);
  color: var(--color);
  border: 0.1ch solid var(--border);
  outline: 0.1ch solid var(--border);

  text-decoration: none;
  border-radius: var(--border-radius-small);
}

.button-action {
  --background: var(--color-details);
  --foreground: var(--color-details-text);
  --border: var(--color-details);

  @extend .button;
}

.button-contact {
  --background: var(--color-background);
  --foreground: var(--color-text);
  --border: var(--color-accent);

  @extend .button;
  text-align: center;
  transition: box-shadow 300ms ease-in;
  box-shadow: inset 0 0 0 0 var(--color-accent);
  &:hover {
    box-shadow: inset 100vmax 0 0 0 var(--color-accent);
    color: var(--color-background);
  }
}

.button-hover-react {
  transition: all 300ms ease-in;
  outline-offset: 0;

  &:hover {
    outline-offset: 1ch;
  }
}