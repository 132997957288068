// header {
//   padding: 0 0;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   .logo-container {
//     .logo {
//       height: 40vh;
//       aspect-ratio: 510.236/708.661;
//     }
//   }
//   min-height: 90vh;
//   @media(max-width: 540px) {
//     flex-direction: column;
//     justify-content: center;
//   }
// }

header {
  padding: 0;
  min-height: 90vh;
  isolation: isolate;


  display: grid;
  gap: 1rem;

  grid-template-columns: auto;
  grid-template-rows: 3rem 1fr 4fr 3fr 1fr;
  grid-template-areas:
    "."
    "."
    "image"
    "description"
    ".";

  .logo-container {
    grid-area: image;
    height: 100%;
    opacity: 0;
    z-index: 10;

    background-image: url(/assets/logo.svg);
    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity 1000ms ease-in-out 1s;
    background-size: 200px 277px;

    &.xr {
      z-index: 1;
      background-image: none;
    }
    &.active {
      transition: opacity 350ms ease-in-out 1s;
      opacity: 1;
    }

  }
  .description {
    align-self: center;
    grid-area: description;
  }
}

@include media-breakpoint-up(lg) {
  header {
    grid-template-columns: auto 4fr 5fr auto;
    grid-template-rows: 1fr 60vh 1fr;
    gap: 5ch;
    grid-template-areas:
      ". . . ."
      ". image description ."
      ". . . .";
  }
}

.logo-container {
  

}