section {
  min-height: 90vh;
  padding-block: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2em;

  & > h2 {
    margin-block-end: 1.5em;
  }
  p.emphatic {
    font-size: 1.25em;
    font-weight: 500;
  }

  article {
    &.columns { 
      -webkit-columns: 2 30ch;
      -moz-columns: 2 30ch;
      columns: 2 30ch;
      column-gap: 4ch;
    }
  }

  .btn {
    border-color: var(--color-secondary) !important;
    color: var(--color-secondary) !important;
    &:hover, &:active {
      border-color: var(--color-primary) !important;
      color: var(--color-inverted) !important;
      background-color: var(--color-secondary);
    }
  }

}

.landing {
  section {
    p { 
      
      line-height: 1.4;
      max-width: 60ch;
      margin-block-end: 1em;

    }

    &.alt {
      --background-color: var(--color-background-alt);
      
      background-color: var(--background-color);
      box-shadow: 0 0 0 100vmax var(--background-color);
      clip-path: inset(0 -100vmax);

      color: var(--color-text-alt);
      .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        color: var(--color-text-alt);
      }
    }
  }

  section#section-about {
    display: grid;
    grid-template-rows: auto auto;
    gap: 2rem;
    @include media-breakpoint-up(lg) {
      grid-template-columns: 3fr 1fr;
    }
    article {
      & > * {
        margin-block-end: var(--separation-block); 
      }
      h2 { max-width: 21ch; }
      p { 
        max-width: 50ch;
        font-size: 1.125em;
      }
    }
    .scaffold {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;

      img.step {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        transition: opacity 200ms ease-in;

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  section#section-brands {
    .brand-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
      grid-template-rows: 1fr 1fr;
      .brand {
        width: 100%;
        min-height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  section#section-products {

    h2 { max-width: 21ch; }
    p { 
      max-width: 50ch;
      font-size: 1.125em;
    }
  
    .feature-list {
      // display: grid;
      // grid-template-columns: auto;
      // grid-template-rows: 1fr min-content 1fr min-content 1fr min-content;
      // gap: 1rem;

      .feature-title { grid-column: span 1; grid-row: span 1; }
      .feature { grid-column: span 1; grid-row: span 1; }
    }
  }
}

@include media-breakpoint-up(lg) {
  .landing {
    section {
      &#section-products {
        .feature-list {
          display: grid;
          gap: var(--separation-block-paragraph);
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr auto auto;

          .feature-title { grid-column: span 1; grid-row: 1; }
          
          .feature { grid-column: span 1; grid-row: 3; }
          .icon { 
            grid-column: span 1;
            grid-row: 2; 
            background-color: var(--color-text);
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 1em 0;
            isolation: isolate;
            position: relative; 
            z-index: 1;
            &:first-of-type {
              box-shadow: 0 0 0 100vmax var(--color-text);
              clip-path: inset(0 -100vmax);
            }
            img { z-index: 5;}
          }
        }
      }
    }
  }
}

section#section-solutions {
  .solutions-grid {
    display: grid;
    grid-auto-rows: 1fr;
    gap: 3ch;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));

    .solution {
      img { max-width: 50%; margin: 1rem auto; display: block; width: 8ch; }
      h3 { text-align: center; }
      p { margin-block-end: calc(var(--separation-block-paragraph) * 0.5); }
      ul {
        padding: 1em 0ch 1em 4ch;
        list-style-type: none;
        margin: 0 auto;
        li {
          margin-block-end: 0.5em;
        }
        li:before {
          content: '✓';
          color: var(--color-accent);
          margin-left: 0ch;
          margin-right: 0.5ch;
          font-weight: 100;
        }
      }
    }
  }
}

section#section-lifecycle {
  article {
    p { margin-block-end: var(--separation-block-paragraph); }
  }
}

section#section-conecta-problem {
  p {
    font-size: 1.5em;
    margin-block-end: var(--separation-block-paragraph);
    max-width: 40ch;
  }
}