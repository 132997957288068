@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300&family=Numans&display=swap');

body {
  font-weight: 300;
  font-size: clamp(0.875rem, calc(0.875rem + 0.25vw), 1.125rem);

  p {
    font-size: 1.25rem;
  }

  font-family: var(--font-family-body);

}

.tech {
  color: var(--color-brand);
}

.accent {
  color: var(--color-text-accent);
}

em {
  color: var(--color-text-accent);
  font-style: normal;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  --size-base: var(--font-size-title-base);
  --size-delta: var(--font-size-title-delta);
  font-family: var(--font-family-titles);
  font-weight: 500;
  color: var(--color-text);
  margin-block-end: 0.5em;
}

h1,
h2,
.h1,
.h2 {
  font-weight: 700;
}

h1,
.h1 {
  font-size: calc(var(--size-base) + 1 * var(--size-delta));
}

h2,
.h2 {
  font-size: calc(var(--size-base) + 0.75 * var(--size-delta));
}

h3,
.h3 {
  font-size: calc(var(--size-base) + 0.5 * var(--size-delta));
}

p {
  &.relevant {
    font-size: 1.5em;
  }
}