.form {
  fieldset {
    border: none;
    margin-block-end: var(--separation-block); 
  }
  .field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-block-end: var(--separation-block);

    label {
      margin-block-end: 0.5em;
    }

    input, textarea {
      outline: 1px solid var(--color-accent);
      border: none;
      padding: 0.25em 1ch;
      background-color: var(--color-background);
      color: var(--color-text);
      width: clamp(50%, 25ch, 75vw);
      margin-block-end: 1em;
      &:focus {
        outline: 3px solid var(--color-accent);
      }
    }
    input {
      line-height: 2;
    }
  }

  .color-shades {
    &.visible {
      display: grid;
    }
    outline: 2px solid var(--color-text);
    display: none;
    grid-template-columns: repeat(9, 1fr);
    width: clamp(50%, 25ch, 75vw);

    .shade {
      grid-column: span 1;
      aspect-ratio: 1 / 4;
    }
  }
}