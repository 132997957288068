$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px) !default;


:root {
  --clr-white: rgb(255, 255, 255
  );
--clr-black: rgb(15, 11, 0);
--clr-beige: #F7F7E4;
--clr-light-gray: #E3DED3;
--clr-mid-gray: #575551;
--clr-dark-gray: #3D3C39;
--clr-light-jet: #343434;
--clr-jet: #312f2f;
--clr-blue: #29339B;
--clr-violet-blue: #6369d1;
--clr-yellow: #ffb700;
--clr-red: #ff3a20;
--clr-yellow-lighter: rgb(255, 196, 0);
--clr-yellow-darker: rgb(204, 146, 0);
--clr-yellow-lightest: hsl(44, 100%, 93%);
--clr-yellow-ultralight: hsl(44, 100%, 97%);
--clr-yellow-darkest: rgb(15, 11, 0);
--clr-black-transparent: rgb(0 0 0 / 20%);

--params-darken-percentage: 10%;
--params-lighten-percentage: 10%;

--color-brand: var(--clr-yellow);
--color-accent: var(--clr-violet-blue);
// Backgrounds
--color-background: var(--clr-white);
--color-background-main: var(--color-background);
--color-background-header: var(--color-background);
--color-background-footer: var(--clr-yellow-ultralight);
--color-background-alt: var(--clr-beige);
--color-background-navbar: rgba(99, 97, 92, 0.5);

// Text color
--color-text: var(--clr-dark-gray);
--color-text-light: var(--clr-light-gray);
--color-text-dark: var(--clr-dark-gray);
--color-text-emphasis: var(--clr-yellow);
--color-text-accent: var(--color-accent);
--color-text-navbar: var(--color-text-light);
--color-text-footer: var(--clr-yellow-darkest);
--color-text-footer-accent: var(--color-text-accent);
--color-link: var(--clr-yellow-darker);
--color-link-visited: var(--clr-yellow);
--color-link-hover: var(--clr-yellow-lighter);

// Shadows and borders
--color-shadow: var(--clr-black-transparent);
--color-border: var(--clr-mid-gray);

// Text in an alternative container
--color-text-alt: var(--clr-dark-gray);
--color-text-alt-light: var(--clr-mid-gray);
--color-text-alt-dark: var(--clr-yellow-darkest);

// Themes
--color-action: var(--clr-yellow);
--color-details: var(--clr-yellow-darker);
--color-information: var(--clr-purple);

// Themes inside-text
--color-action-text: var(--clr-yellow-darkest);
--color-details-text: var(--clr-yellow-lightest);
--color-information-text: var(--clr-white);

// Themes alternatives
--color-action-hover: var(--clr-yellow-lighter);
--color-details-hover: var(--clr-yellow);
--color-information-hover: Lighten(var(--color-information), var(--params-lighten-percentage));



--border-radius-base: 0.375rem;
--border-radius-square: 0rem;
--border-radius-small: 0.15rem;
--border-radius-rounded: 0.75rem;
--border-radius-circle: 100%;

--border-radius-button: 0.375rem;
--border-radius-card:   0.750rem;

--box-shadow-base: 0 6px 20px var(--color-shadow);
--box-shadow-closest: 0 3px 4px var(--color-shadow);
--box-shadow-close: 0 5px 5px var(--color-shadow);
--box-shadow-far: 1px 10px 20px var(--color-shadow);

--font-family-titles: 'Numans', 'Lato', 'Helvetica Neue', 'Helvetica', sans-serif;
--font-family-body: 'Lato', 'Helvetica', sans-serif;
--font-family-code: monospace;

--font-size-base: 1rem;
--font-size-title-base: 1.0625rem;
--font-size-title-delta: 2.5vh;

--separation-block: 3rem;
--separation-block-sm: 1rem;
--separation-block-lg: 5rem;
--separation-block-xl: 25vh;
--separation-block-paragraph: 2em;

}

@media (prefers-color-scheme: dark) {

  :root {
    --color-accent: var(--clr-yellow);
    // Backgrounds
    --color-background: var(--clr-black);
    --color-background-main: var(--color-background);
    --color-background-header: var(--color-background);
    --color-background-footer: var(--clr-yellow-darker);
    --color-background-alt: var(--clr-jet);
    --color-background-navbar: rgba(99, 97, 92, 0.2);

    // Text color
    --color-text: var(--clr-white);
    --color-text-light: var(--clr-light-gray);
    --color-text-dark: var(--clr-white);
    --color-text-emphasis: var(--clr-yellow);
    --color-text-alt: var(--clr-white);
    --color-text-footer: var(--clr-yellow-darkest);
    --color-text-footer-accent: var(--clr-yellow-lightest);

    --color-link: var(--clr-yellow);
    --color-link-visited: var(--clr-yellow-darker);
    --color-link-hover: var(--clr-yellow-lighter);

    --color-border: var(--clr-mid-gray);
  }

}

.ratio-logo {
  aspect-ratio: 510.236/708.661;
}

section.header {
  margin-block-end: var(--separation-block-lg);
}


@include media-breakpoint-up(lg) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: map-get($grid-breakpoints, lg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);

  :where(div.alt, section.alt) {
    background-color: var(--color-background-alt);
    color: var(--color-text-alt);
    
    box-shadow: 0 0 0 100vmax var(--color-background-alt);
    clip-path: inset(0 -100vmax);

    h2, h3, h4 { color: var(--color-text-alt); }
  }

  :where(p.alt, span.alt) {
    color: var(--color-text-alt);
  }

  // nav.navbar {
  //   background-color: var(--color-background-navbar);
  //   color: var(--color-text-navbar);
  //   position: fixed;
  //   display: flex;
  // }
  header {
    background-color: var(--color-background-header);
    color: var(--color-text);
  }

  main {
    background-color: var(--color-background-main);
    color: var(--color-text);
    margin-block-end: 10vh;
    margin: 0 auto;
    padding: 0 1rem;
  }

  footer {
    @extend .container;
    box-shadow: 0 0 0 100vmax var(--color-background-footer);
    clip-path: inset(0 -100vmax);
    background-color: var(--color-background-footer);
    color: var(--color-text-footer);
    min-height: 15vh;
    margin: 0 auto;
  }

  a {
    --foreground: var(--color-link);
    --hover: var(--color-link-hover);
    color: var(--foreground);

    &:visited,
    &:link {
      color: var(--foreground);
    }

    &:hover {
      color: var(--color-link-hover);
    }
  }
}